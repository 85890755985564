import React from "react";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as EyesClose } from "../../../assets/icons/eyes-close.svg";
import { ReactComponent as EyesOpen } from "../../../assets/icons/eyes-open.svg";
import { ReactComponent as Structures } from "../../../assets/icons/structures.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import { ReactComponent as Copy } from "../../../assets/icons/copy.svg";
import { ReactComponent as File } from "../../../assets/icons/file.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import { ReactComponent as ArrowSelect } from "../../../assets/icons/arrow-select.svg";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as Save } from "../../../assets/icons/save.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as PlusCircledOutline } from "../../../assets/icons/plus-circle.svg";
import { ReactComponent as Warning } from "../../../assets/icons/warning.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { ReactComponent as DatasetWeather } from "../../../assets/icons/dataset_weather.svg";
import { ReactComponent as DatasetElectrical } from "../../../assets/icons/dataset_electrical.svg";
import { ReactComponent as DownloadCsv } from "../../../assets/icons/download-csv.svg";
import { ReactComponent as DownloadFile } from "../../../assets/icons/download-file.svg";
import { ReactComponent as DownloadImage } from "../../../assets/icons/download_image.svg";
import { ReactComponent as TerrainIcon } from "../../../assets/icons/terrain-icon.svg";
import { ReactComponent as HelpCircle } from "../../../assets/icons/help_circle.svg";
import { ReactComponent as SelectTemplate } from "../../../assets/icons/select-template.svg";
import { ReactComponent as Number } from "../../../assets/icons/number.svg";
import { ReactComponent as CheckCircle } from "../../../assets/icons/check-circle.svg";
import { ReactComponent as CrossCircle } from "../../../assets/icons/cross-circle.svg";
import { ReactComponent as Calculator } from "../../../assets/icons/calculator.svg";
import { ReactComponent as Success } from "../../../assets/icons/success.svg";
import { ReactComponent as Bolt } from "../../../assets/icons/bolt.svg";
import { ReactComponent as Minus } from "../../../assets/icons/minus.svg";

export enum ICON_NAMES_ENUM {
  logo = "logo",
  eyes_close = "eyes_close",
  eyes_open = "eyes_open",
  structures = "structures",
  edit = "edit",
  delete = "delete",
  copy = "copy",
  close = "close",
  file = "file",
  arrow = "arrow",
  arrow_select = "arrow_select",
  upload = "upload",
  search = "search",
  save = "save",
  plus = "plus",
  arrow_down = "arrow_down",
  plus_circle = "plus_circle",
  warning = "warning",
  dataset_weather = "dataset_weather",
  dataset_electrical = "dataset_electrical",
  download_image = "download_image",
  terrain_icon = "terrain_icon",
  download_file = "download_file",
  help_circle = "help_circle",
  select_template = "select_template",
  number = "number",
  check_circle = "check_circle",
  cross_circle = "cross_circle",
  calculator = "calculator",
  success = "success",
  bolt = "bolt",
  minus = "minus",
}

const ICONS = {
  logo: Logo,
  eyes_close: EyesClose,
  eyes_open: EyesOpen,
  structures: Structures,
  edit: Edit,
  delete: Delete,
  copy: Copy,
  close: Close,
  file: File,
  arrow: Arrow,
  arrow_select: ArrowSelect,
  upload: Upload,
  search: Search,
  save: Save,
  plus: Plus,
  arrow_down: ArrowDown,
  plus_circle: PlusCircledOutline,
  warning: Warning,
  dataset_weather: DatasetWeather,
  dataset_electrical: DatasetElectrical,
  download_csv: DownloadCsv,
  download_image: DownloadImage,
  terrain_icon: TerrainIcon,
  download_file: DownloadFile,
  help_circle: HelpCircle,
  select_template: SelectTemplate,
  number: Number,
  check_circle: CheckCircle,
  cross_circle: CrossCircle,
  calculator: Calculator,
  success: Success,
  bolt: Bolt,
  minus: Minus,
};

interface IconProps {
  className?: string;
  name: ICON_NAMES_ENUM;
}

export const Icon: React.FC<IconProps> = ({ className, name, ...props }) => {
  if (!(name in ICONS)) return null;

  const Component = ICONS[name];

  return (
    <span className={className}>
      <Component {...props} />
    </span>
  );
};
