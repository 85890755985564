import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToPng from "utils/chartToPng";
import chartToCsv from "utils/chartToCsv";
import CustomTick from "components/CustomTick/CustomTick";
import { getProductionTable } from "store/analisys/api";
import { useParams, useSearchParams } from "react-router-dom";
import WaterBalanceResultMenuMessages from "./WaterBalanceResultMenuMessages";
import { TableSortLabel } from "@mui/material";

const irrUnites = ["Fraction", "Daily PAR"];

class IrradianceContentProps {
  result: any;
}

const ProductionContent = ({ result }: IrradianceContentProps) => {
  const intl = useIntl();
  const { client_id, project_id, water_balance_id, id, simulation_water_balance_id } = useParams();
  const [responseData, setResponseData] = useState<any>(null);
  const [tableValues, setTableValues] = useState([]);

  const productionTableTypeOptions = [
    { label: "Stage Table", value: "STAGE" },
    { label: "Month Table", value: "MONTH" },
  ];
  const [
    selectedProductionTableTypeOptions,
    setSelectedProductionTableTypeOptions,
  ] = useState<"STAGE" | "MONTH">("MONTH");

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    const chart_type = searchParams.get('chart_type')
    if (productionTableTypeOptions.find((item) => item.value == chart_type))
      setSelectedProductionTableTypeOptions(chart_type as any)
    else
      newParams.set("chart_type", selectedProductionTableTypeOptions)

    setSearchParams(newParams)
  }, [])

  const selectProductionTableTypeOptions = (value: any) => {
    setSelectedProductionTableTypeOptions(value);
    const newParams = new URLSearchParams(searchParams);
    newParams.set("chart_type", value)
    setSearchParams(newParams)
  }

  useEffect(() => {
    if (responseData) {
      if (selectedProductionTableTypeOptions == "MONTH") setTableValues(responseData!.month_array);
      else if (selectedProductionTableTypeOptions == "STAGE")
        setTableValues(responseData!.stage_array);
    }
  }, [])

  useEffect(() => {
    getProductionTable({
      clientId: client_id,
      projectId: project_id,
      simulationId: water_balance_id,
      id: id || simulation_water_balance_id,
    }).then((res) => {
      setResponseData(res.data), setTableValues(res.data.month_array);
    });
  }, [client_id, project_id, water_balance_id, id]);

  const [inactiveKeysPeriodProduction, setInactiveKeyPeriodProduction] =
    useState(Array<string>());


  if (!responseData) return <div style={{ height: "100vh" }} />;

  return (
    <div className={styled.list}>
      <div className={styled.optionContainer}>
        <div className={styled.uniteSelector}>
          {" "}
          <Select
            label="Select Chart Type"
            labelPosition="left"
            options={productionTableTypeOptions}
            value={productionTableTypeOptions.find(
              (item) => item.value == selectedProductionTableTypeOptions
            )}
            onChange={(e: any) => {
              selectProductionTableTypeOptions(e.value);
            }}
            isSearchable={false}
            menuHeight={400}
          />
        </div>
      </div>
      <div>
        <div>
          <div className={styled.downloadContainer}>
            <div className={styled.chartTitle}>
              {selectedProductionTableTypeOptions == "MONTH"
                ? intl.formatMessage(SimulationResultBodyMessages.ProdPerMonth)
                : intl.formatMessage(
                  SimulationResultBodyMessages.ProdPerPeriod
                )}
            </div>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng(
                  "Table " +
                  (selectedProductionTableTypeOptions == "MONTH"
                    ? intl.formatMessage(
                      SimulationResultBodyMessages.ProdPerMonth
                    )
                    : intl.formatMessage(
                      SimulationResultBodyMessages.ProdPerPeriod
                    ))
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  selectedProductionTableTypeOptions == "STAGE" ? responseData.stage_array : responseData.month_array,
                  `${result.simulation.name} Production per ${selectedProductionTableTypeOptions == "MONTH"
                    ? "Month"
                    : "Stage"
                  } ${result.simulation.crop_for_water_balance.name}`
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
          <div
            id={
              "Table " +
              (selectedProductionTableTypeOptions == "MONTH"
                ? intl.formatMessage(SimulationResultBodyMessages.ProdPerMonth)
                : intl.formatMessage(
                  SimulationResultBodyMessages.ProdPerPeriod
                ))
            }
          >
            <table className={styled.dataTable}>
              <thead>
                <tr>
                  <th rowSpan={2}>
                    {selectedProductionTableTypeOptions == "MONTH" ? (
                      "Month Name"
                    ) : (
                      <FormattedMessage {...CropCreateMessages.stageName} />
                    )}
                  </th>
                  <th colSpan={2}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.production}
                    />
                  </th>
                  <th rowSpan={2}>
                    <FormattedMessage {...WaterBalanceResultMenuMessages.diff} values={{ unit: "kWh/kWc" }} />
                    <div className={styled.subTitle}>
                      <FormattedMessage {...WaterBalanceResultMenuMessages.diff_subtitle} />
                    </div>
                  </th>
                  <th rowSpan={2}>
                    <FormattedMessage {...WaterBalanceResultMenuMessages.variation} />
                    <div className={styled.subTitle}>
                      <FormattedMessage {...WaterBalanceResultMenuMessages.diff_subtitle} />
                    </div>
                  </th>
                </tr>
                <tr className={styled.specialCaseTable}>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.prodSunTracking}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.prodAgriPv}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(selectedProductionTableTypeOptions == "STAGE" ? responseData.stage_array : responseData.month_array).map((values: any) => (
                  <tr
                    key={
                      selectedProductionTableTypeOptions == "STAGE"
                        ? values.period.name
                        : values.month
                    }
                  >
                    <td>
                      {selectedProductionTableTypeOptions == "STAGE"
                        ? values.period.name
                        : values.month}
                    </td>
                    <td>{values.prod_sun_tracking}</td>
                    <td>{values.prod_agri_PV}</td>
                    <td>{values.diff}</td>
                    <td>{values.variation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={styled.chartContainer}>
        <div className={styled.optionContainer}>
          <TooltipMUI
            title={intl.formatMessage(SimulationResultBodyMessages.hintLegend)}
          >
            <div className={styled.chartTitle}>
              {selectedProductionTableTypeOptions == "MONTH"
                ? intl.formatMessage(SimulationResultBodyMessages.ProdPerMonth)
                : intl.formatMessage(
                  SimulationResultBodyMessages.ProdPerPeriod
                )}
            </div>
          </TooltipMUI>
          <div className={styled.downloadContainer}>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng(
                  selectedProductionTableTypeOptions == "MONTH"
                    ? intl.formatMessage(
                      SimulationResultBodyMessages.ProdPerMonth
                    )
                    : intl.formatMessage(
                      SimulationResultBodyMessages.ProdPerPeriod
                    )
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  tableValues,
                  `${result.simulation.name} Production per ${selectedProductionTableTypeOptions == "MONTH"
                    ? "Month"
                    : "Stage"
                  } ${result.simulation.crop_for_water_balance.name}`
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
        </div>
        <div
          id={
            selectedProductionTableTypeOptions == "MONTH"
              ? intl.formatMessage(SimulationResultBodyMessages.ProdPerMonth)
              : intl.formatMessage(SimulationResultBodyMessages.ProdPerPeriod)
          }
        >
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={(selectedProductionTableTypeOptions == "STAGE" ? responseData.stage_array : responseData.month_array).filter(
                (val: any) =>
                  (val.month && val.month != "Sum") ||
                  (val.period && val.period.name != "Sum")
              )}
              width={500}
              height={500}
              margin={{
                top: 10,
                right: 30,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={
                  selectedProductionTableTypeOptions == "STAGE"
                    ? "period.name"
                    : "month"
                }
                tick={<CustomTick />}
                height={75}
                interval={0}
              />
              <YAxis yAxisId="left" interval={0} orientation="left">
                <Label
                  style={{ textAnchor: "middle" }}
                  position={"insideLeft"}
                  angle={270}
                  fontSize={12}
                  value={intl.formatMessage(
                    SimulationResultBodyMessages.prodAgriPV
                  )}
                />
              </YAxis>
              <Tooltip content={CustomTooltip} />
              <Legend
                onClick={(o) => {
                  if (
                    !inactiveKeysPeriodProduction.find(
                      (inactiveKey) => inactiveKey == o.dataKey
                    )
                  )
                    setInactiveKeyPeriodProduction([
                      ...inactiveKeysPeriodProduction,
                      o.dataKey,
                    ]);
                  else {
                    var temp = inactiveKeysPeriodProduction;
                    temp.splice(
                      inactiveKeysPeriodProduction.findIndex(
                        (inactiveKey) => inactiveKey == o.dataKey
                      ),
                      1
                    );
                    setInactiveKeyPeriodProduction([...temp]);
                  }
                }}
              />
              <Bar
                type="monotone"
                dataKey={"prod_sun_tracking"}
                name="Production sun tracking"
                yAxisId="left"
                fill={"#AA6666"}
                hide={
                  inactiveKeysPeriodProduction.find(
                    (inactiveKey) => inactiveKey == "prod_sun_tracking"
                  )
                    ? true
                    : false
                }
                unit={"kWh/kWc"}
              />
              <Bar
                type="monotone"
                dataKey={"prod_agri_PV"}
                name="Production agriPV"
                yAxisId="left"
                fill={"#66AA66"}
                hide={
                  inactiveKeysPeriodProduction.find(
                    (inactiveKey) => inactiveKey == "prod_agri_PV"
                  )
                    ? true
                    : false
                }
                unit={"kWh/kWc"}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ProductionContent;
